<template>
    <div class="news-work">
        <div class="news-wrapper main flex-between">
            <div class="left-section">
                <ul class="news-lists">
                    <li class="flex-align-between list-box" v-for="(li, index) in lists" :key="index"
                        @click="toNoticeDetail(li)">
                        <img v-if="li.cover" :src="li.cover" alt="" />
                        <img v-else src="../../assets/images/home/notice.jpeg" alt="" />
                        <div class="article">
                            <h3 class="f16 wes">{{li.title}}</h3>
                            <p class="f12 wes-3">{{li.introduction}}</p>
                            <div class="summarys flex-align">
                                <span>查看详情</span>
                                <!-- <span>编辑：{{li.author}}</span>
                                <span>阅读量：{{li.time || 0}}</span> -->
                            </div>
                        </div>
                        <div class="time">
                            <div class="date">
                                <div class="day">{{dayjs(li.updateTime).format('DD')}}</div>
                                <div class="month">{{dayjs(li.updateTime).format('YYYY')}}/{{dayjs(li.updateTime).format('MM')}}</div>
                            </div>
                        </div>
                    </li>
                </ul>
                <empty :show="lists && lists.length === 0"></empty>
                <div v-if="lists.length > 0" class="page-box">
                    <el-pagination background @current-change="handleCurrentChange" layout="prev, pager, next"
                        :total="total">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                lists: [],
                total: null,
                pageSize: 10,
                pageNum: 1,
                common:Common
            }
        },
        methods: {
            handleCurrentChange(val) {
                this.pageNum = val
                this.getlist()
            },
            async getlist() {
                let params = {
                    projectId: this.$route.query.projectId,
                    //   projectId:2,
                    page: this.pageNum,
                    limit: this.pageSize,
                }
                let resData = await this.$Api.Home.getNewsListByProjectId(params);
                this.total = resData.data.totalCount;
                this.lists = resData.data.list;
            },
            toNoticeDetail(item) {
                let breadListLength = this.$store.state.basics.breadList.length
                this.$router.push({
                    path: '/news/notice',
                    query: {
                        projectId: this.$route.query.projectId,
                        id: item.id,
                        breadNumber: breadListLength + 1
                    }
                })
            }

        },
        mounted() {

            this.getlist();
        },
        destroyed() {}

    }
</script>

<style lang="less" scoped>
    @basisColor: #1c4b90;
    @bgColor: #eef3f6;
    @white: #fff;

    .main {
        width: 1200px;
        margin: 0 auto;
    }

    .bread-crumb {
        box-sizing: border-box;
        padding-left: 40px;
        line-height: 55px;
    }

    .news-work {
        // background-color: @white;
        min-height: 100vh;
        height: auto !important;
        height: 500px;
        overflow: visible;

        .left-section {
            width: 1200px;

            .news-lists {
                // padding: 30px 0 0;

                li {
                    margin-bottom: 21px;
                    padding: 0 40px 0 0;
                    min-height: 120px;
                    cursor: pointer;
                    border: 1px solid #E6E6E6;
                    //     &:hover .article h3,
                    //     &:hover .article p,
                    //     &:hover .article .summarys,
                    //     &:hover .day,
                    //     &:hover .month {
                    //       color: #900000;
                    //   }
                }

                img {
                    display: inline-block;
                    margin-right: 25px;
                    width: 280px;
                    height: 210px;
                    background-color: @bgColor;
                }
            }

            .article {
                // border:1px solid #999999;
                flex: 1;
                width: 0;
                margin-right: 50px;

                &.notImg {
                    width: auto;
                }

                p {
                    margin: 20px 0;
                }
            }

            .time {
                font-weight: bold;
                font-size: 16px;
            }

            .date {
                width: 110px;
                text-align: center;
                border: 1px solid #E6E6E6;

                .day {
                    font-size: 26px;
                    font-family: Adobe Heiti Std;
                    font-weight: bold;
                    padding: 10px 0;
                    color: #999999;
                }

                .month {
                    font-size: 16px;
                    font-family: Adobe Heiti Std;
                    font-weight: bold;
                    padding: 10px 0;
                    color: #999999;
                }
            }

            .summarys span {
                font-size: 14px;
                color: #CC4C4D;
                padding: 5px 20px;
                border: 1px solid #CC4C4D;
                border-radius: 16px;
                // &:hover{
                //     margin-top:-5px;
                //     transition-duration: 300ms;
                //   }
            }

            .img-box {
                margin: 18px 0;
            }

            .more-btn {
                display: block;
                margin: 50px auto 28px;
                border: 1px solid @basisColor;
                border-radius: 3px;
                width: 200px;
                line-height: 40px;
                font-size: 12px;
                color: @basisColor;
                text-align: center;
                cursor: pointer;
            }

            .nothing {
                display: block;
                margin: 50px auto 28px;
                line-height: 40px;
                font-size: 14px;
                color: #a1a1a1;
                text-align: center;
                cursor: pointer;

                hr {
                    width: 200px;
                    display: inline-block;
                    vertical-align: middle;
                    border-color: #eee;
                }

                span {
                    padding: 20px;
                }
            }
        }

        .page-box {
            text-align: center;
            padding-bottom: 33px;
            margin-right: 75px;

            // .el-pagination .el-pager .active {
            //     background-color: #CC4C4D;
            // }
            // .el-pagination.is-background .el-pager li:hover {
            //     color: #CC4C4D;
            // }

        }
    }
</style>